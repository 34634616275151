import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    toggle(target:HTMLElement) {
        this.descriptions.forEach(description => {
            if (description.id == target.dataset.show) {
                /*
                 * We need to unhide the item before transitioning otherwise
                 * to margin-top transition doesn't work
                 */
                if (description.classList.contains('hidden')) {
                    description.classList.remove('hidden')
                    window.requestAnimationFrame(_ => {
                        description.classList.remove('invisible')
                        description.classList.add('active')
                    })
                } else {
                    description.classList.remove('active')
                    setTimeout(_ => {
                        description.classList.add('invisible')
                        description.classList.add('hidden')
                    }, 500)
                }

                for (const svg of target.getElementsByTagName('svg')) {
                    (svg as SVGElement).classList.toggle('active')

                    /*
                    if ((svg as SVGElement).classList.toggle('hidden')) {
                        svg.classList.remove('hidden')
                    } else {
                        svg.classList.add('hidden')
                    }
                     */
                }
            }
        })
    }

    click(event:Event) {
        this.toggle(event.currentTarget as HTMLElement)
        ;(document.activeElement as HTMLElement).blur()
    }

    close(event:Event) {
        const linkTarget = this.links.find(link => link.dataset.show == (event.currentTarget as HTMLElement).dataset.show)
        linkTarget && this.toggle(linkTarget)
        ;(document.activeElement as HTMLElement).blur()
    }

    keydown(event:KeyboardEvent) {
        if (event.key == 'Enter' || event.key == ' ') {
            event.preventDefault()
            this.toggle((event.currentTarget as HTMLElement).parentElement)
        }
    }

    get descriptions(): HTMLElement[] {
        return this.targets.findAll('description') as HTMLElement[]
    }

    get links(): HTMLAnchorElement[] {
        return this.targets.findAll('link') as HTMLAnchorElement[]
    }
}