import {IndeedEvent} from "../events/IndeedEvent"
import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select/dist/esm/tom-select.popular"

export default class extends Controller {
    onView: any
    onApply: any
    onKeydown: any
    _currentJob: string
    fieldsData: any
    fileUploadWrapper: HTMLElement
    links: HTMLElement[]

    constructor(context) {
        super(context)
        this.onView = this.view.bind(this)
        this.onApply = this.apply.bind(this)
        this.onKeydown = this.keydown.bind(this)

        for (const select of Array.from((this.element as HTMLElement).getElementsByTagName('select'))) {
            new TomSelect(select, {
                create: false,
                maxItems: 1,
                closeAfterSelect: true,
                onBlur: () => {
                    const event = new KeyboardEvent('keydown', {
                        bubbles: true,
                        key: 'Tab'
                    })
                    select
                        .closest('.form-control')
                        .getElementsByTagName('input')[0]
                        .dispatchEvent(event)
                },
            })
        }

        const updateHeight = (event: Event) => {
            const textarea = (event.target as HTMLInputElement)
            textarea.style.height = 'auto'
            textarea.style.height = textarea.scrollHeight + 4 + 'px'
        }

        for (const textarea of Array.from((this.element as HTMLElement).getElementsByTagName('textarea'))) {
            textarea.addEventListener('input', updateHeight)
        }
    }

    view(event: Event) {
        this.currentJob = (event.target as HTMLElement).dataset.jobId
        this.fieldsData = JSON.parse(this.description.dataset.fields)
        this.show(this.description)
        this.hideForm()
        this.initLinks()
    }

    apply(event: Event) {
        this.currentJob = (event.target as HTMLElement).dataset.jobId
        this.fieldsData = JSON.parse((this.element as HTMLElement).dataset.fields)
        this.hide(this.descriptions)
        this.showForm()
    }

    connect() {
        document.addEventListener('careers.view', this.onView)
        document.addEventListener('careers.apply', this.onApply)
        ;(this.element as HTMLElement).addEventListener('keydown', this.onKeydown)

        const dataset = (this.element as HTMLElement).dataset
        this.currentJob = dataset.currentJob ?? null
        if (this.currentJob) {
            this.fieldsData = JSON.parse(this.description.dataset.fields)
        }
    }

    disconnect() {
        (this.element as HTMLElement).removeEventListener('keydown', this.onKeydown)
    }

    async submit(event: Event) {
        event.preventDefault()

        this.submitButton.disabled = true;
        this.uploadError.classList.add('hidden')

        const formData = await this.formatFormData(this.form)

        fetch(this.form.action, {
            method: 'POST',
            body: formData,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            },
        })
            .then(response => response.json())
            .then(result => {
                if (result.errors) {
                    throw new Error()
                }

                this.submitButton.disabled = false

                this.hide([
                    this.applyButton,
                    this.formFields,
                    ...this.descriptions,
                    ...this.jobs
                ])

                this.show(this.success)
                this.form.reset()
                if (this.fileUploadWrapper) {
                    this.fileUploadWrapper.classList.remove('has-value')
                    this.fileUploadWrapper.dataset.text = 'Click to Upload'
                }

                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
            })
            .catch(error => {
                this.submitButton.disabled = false
                this.show(this.uploadError)
            });
    }

    showForm() {
        //Allow modal to be opened by keypress
        if (event instanceof KeyboardEvent) {
            if ([' ', 'Enter'].includes((event as KeyboardEvent).key)) {
                event.preventDefault()
                event.stopImmediatePropagation()
            } else {
                return
            }
        }

        this.hide([
            this.applyButton,
            this.success,
            this.uploadError
        ])

        this.updateFields()
        this.show(this.formFields)

        //Do this again so tab order works
        this.initLinks()

        this.submitButton.disabled = false
    }

    hideForm() {
        this.hide([
            this.success,
            this.uploadError,
            this.formFields
        ])

        this.show(this.applyButton)
    }

    updateFields() {
        this.hide(this.fields)

        for (const fieldName in this.fieldsData) {
            const field = this.fields.find(field => field.dataset.field == fieldName)
            if (!field) {
                continue;
            }

            this.show(field)
            const input = field.querySelector('#' + fieldName)
            if (this.fieldsData[fieldName]) {
                field.classList.add('required')
                input.setAttribute('required', "")
            } else {
                field.classList.remove('required')
                input.removeAttribute('required')
            }

            this.updateDisabilityFields()
        }

        this.hide(this.questionnaires)
        this.show(this.questionnaire)

        /*
         * Set required attribute on mandatory fields for the current questionnaire
         */
        const questionnaireId = this.questionnaire ? this.questionnaire.dataset.questionnaireId : null
        if (questionnaireId) {
            this.questionInputs.forEach(element => {
                if ((element.dataset.questionnaire == questionnaireId) && (element.dataset.mandatory == 'Yes')) {
                    element.setAttribute('required', "")
                    return
                }

                element.removeAttribute('required')
            })
        }

        if (this.fieldsData['eeo_gender'] || this.fieldsData['eeo_race'] || this.fieldsData['eeo_disability']) {
            this.show(this.eeo)
        } else {
            this.hide(this.eeo)
        }

        if (this.fieldsData['eeoc_veteran']) {
            this.show(this.eeocVeteran)
        } else {
            this.hide(this.eeocVeteran)
        }

        if (this.fieldsData['eeoc_disability']) {
            this.show(this.eeocDisability)
        } else {
            this.hide(this.eeocDisability)
        }
    }

    async formatFormData(form: HTMLFormElement): Promise<FormData> {
        const formData = new FormData(form)
        const data = new FormData()

        const includedFields = [
            'first_name',
            'last_name',
            'phone',
            'email',
            'resume',
        ]

        let applicantFields = {}

        formData.forEach((value, key, formData) => {
            if (includedFields.includes(key)) {
                data.set(key, value)
                return
            }

            if (this.fieldsData.hasOwnProperty(key)) {
                applicantFields[key] = value
            }
        })

        data.set('job', this.currentJob)
        data.set('applicant_fields', JSON.stringify(applicantFields))


        if (this.questionnaire) {
            const questionnaireId = this.questionnaire.dataset.questionnaireId
            const questionnaireFields = {}
            for (const entry of formData) {
                const [key, value] = entry
                if (key.startsWith(questionnaireId)) {
                    if (typeof value == 'object') { //Handle file

                    }

                    if (value !== "") {
                        const _key = key.substr(questionnaireId.length + 1)
                        if (typeof value == 'object') {
                            questionnaireFields[_key] = await this.convertFileToBase64(value)
                        } else {
                            const currentValue: string = questionnaireFields.hasOwnProperty(_key) ? (questionnaireFields[_key] + "\r\n") : ''
                            questionnaireFields[_key] = currentValue + value
                        }
                    }
                }
            }

            data.set('questionnaire_answers', JSON.stringify(questionnaireFields))
            data.set('questionnaire_id', this.description.dataset.questionnaireId)
        }

        return data
    }

    initLinks() {
        const job = this.jobs.find(job => job.dataset.jobId == this.currentJob) as HTMLElement

        //If the applyButton is hidden then get all elements include form inputs
        if (this.applyButton.classList.contains('hidden')) {
            this.links = [
                ...Array.from(job.getElementsByTagName('a')),
                ...Array.from(this.form.querySelectorAll('a:not(.hidden), button:not(.hidden), .form-control:not(.hidden) input, .form-control:not(.hidden) > textarea'))
            ].filter((element: HTMLElement) => {
                if (element.parentElement.classList.contains('hidden') /*|| element.tabIndex == -1*/) {
                    return false
                }

                if (!(element.offsetWidth || element.offsetHeight || element.getClientRects().length)) {
                    return false
                }

                return true
            }) as HTMLElement[]

            //Put focus on the first input
            (this.form.querySelector('input#first_name') as HTMLElement).focus()
        }
        //Otherwise just get the share and apply button
        else {
            this.links = [
                ...Array.from(job.getElementsByTagName('a')),
                this.applyButton
            ]

            //Put focus on the heading
            this.links[0].focus()
        }
    }

    keydown(event: KeyboardEvent) {
        if (event.key != 'Tab') {
            return
        }

        event.preventDefault()
        event.stopImmediatePropagation()

        const target = event.target as HTMLElement
        let nextLink: HTMLElement = this.links[0]

        this.links.forEach((link, index) => {
            if (link != target) {
                link.tabIndex = -1
                return
            }

            if (event.shiftKey) {
                nextLink = (index == 0) ? this.links[this.links.length - 1] : this.links[index - 1]
            } else {
                nextLink = (index < this.links.length - 1) ? this.links[index + 1] : this.links[0]
            }
        })

        nextLink.tabIndex = 0
        nextLink.focus()
    }

    updateDisabilityFields() {
        const eeocDisabilityField = this.fields.find(field => field.dataset.field == "eeoc_disability") as HTMLSelectElement

        if (!eeocDisabilityField) {
            return
        }

        const select = eeocDisabilityField.getElementsByTagName('select')[0]

        for (const field of this.fields.filter(field =>
            ['eeoc_disability_signature', 'eeoc_disability_date'].includes(field.dataset.field))) {
            const input = field.getElementsByTagName('input')[0]
            if (select.value == '0') {
                field.classList.remove('required')
                input.removeAttribute('required')
            } else {
                field.classList.add('required')
                input.setAttribute('required', '')
            }
        }
    }

    toggleSelect(event: KeyboardEvent) {
        if ([' ', 'Enter'].includes(event.key)) {
            event.preventDefault()
            event.stopImmediatePropagation()

            const checkbox = event.target as HTMLInputElement
            checkbox.checked = !checkbox.checked
        }
    }

    focusElement(event: Event) {
        const formControl = (event.target as HTMLElement).closest('.form-control')
        if (formControl) {
            formControl.classList.add('focus')
        }
    }

    unfocusElement(event: Event) {
        const formControl = (event.target as HTMLElement).closest('.form-control')
        if (formControl) {
            formControl.classList.remove('focus')
        }
    }

    async convertFileToBase64(file) {

        return new Promise((resolve) => {
            const reader = new FileReader();

            reader.addEventListener('load', (event: ProgressEvent<FileReader>) => {
                const binaryData = reader.result as string
                resolve(window.btoa(binaryData))
            })

            reader.readAsBinaryString(file);
        })
    }

    fileUploaded(event: Event) {
        const input = event.currentTarget as HTMLInputElement

        this.fileUploadWrapper = input.closest('.file-upload-wrapper') as HTMLElement
        this.fileUploadWrapper.dataset.text = input.value.replace(/.*(\/|\\)/, '')
        this.fileUploadWrapper.classList.add('has-value')
    }

    hide(elements: HTMLElement | HTMLElement[]) {
        if (Array.isArray(elements)) {
            for (const element of elements) {
                element.classList.add('hidden')
            }

            return
        }

        elements && elements.classList.add('hidden')
    }

    show(elements: HTMLElement | HTMLElement[]) {
        if (Array.isArray(elements)) {
            for (const element of elements) {
                element.classList.remove('hidden')
            }

            return
        }

        elements && elements.classList.remove('hidden')
    }


    set currentJob(value: string) {
        if (this._currentJob == value) {
            return
        }

        this._currentJob = value
        this.indeedButton.dispatchEvent(new IndeedEvent(this._currentJob))
    }

    get currentJob(): string {
        return this._currentJob
    }

    get description(): HTMLElement {
        return this.descriptions.find(description => description.dataset.jobId == this.currentJob)
    }

    get questionnaire(): HTMLElement {
        const questionnaireId = this.description.dataset.questionnaireId
        return questionnaireId ? this.questionnaires.find(questionnaire => {
            return questionnaire.dataset.questionnaireId == questionnaireId
        }) : null
    }

    get formFields(): HTMLElement {
        return this.targets.find('formFields') as HTMLElement
    }

    get success(): HTMLElement {
        return this.targets.find('success') as HTMLElement
    }

    get uploadError(): HTMLElement {
        return this.targets.find('uploadError') as HTMLElement
    }

    get applyButton(): HTMLElement {
        return this.targets.find('applyButton') as HTMLElement
    }

    get submitButton(): HTMLInputElement {
        return this.targets.find('submitButton') as HTMLInputElement
    }

    get indeedButton(): HTMLInputElement {
        return this.targets.find('indeedButton') as HTMLInputElement
    }

    get fileInput(): HTMLElement {
        return this.targets.find('fileInput') as HTMLElement
    }

    get descriptions(): HTMLElement[] {
        return this.targets.findAll('description') as HTMLElement[]
    }

    get jobs(): HTMLElement[] {
        return this.targets.findAll('job') as HTMLElement[]
    }

    get fields(): HTMLElement[] {
        return this.targets.findAll('field') as HTMLElement[]
    }

    get questionInputs(): HTMLElement[] {
        return this.targets.findAll('questionInput').filter(questionInput => {
            questionInput.id.includes(this.questionnaire.id);
        }) as HTMLElement[]
    }

    get questionnaires(): HTMLElement[] {
        return this.targets.findAll('questionnaire') as HTMLElement[]
    }

    get eeo(): HTMLElement {
        return this.targets.find('eeo') as HTMLElement
    }

    get eeocVeteran(): HTMLElement {
        return this.targets.find('eeoc_veteran') as HTMLElement
    }

    get eeocDisability(): HTMLElement {
        return this.targets.find('eeoc_disability') as HTMLElement
    }

    get form(): HTMLFormElement {
        return this.targets.find('form') as HTMLFormElement
    }
}