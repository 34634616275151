import * as Turbo from "@hotwired/turbo"
import { Application } from "@hotwired/stimulus"
import { definitionsFromGlob } from "stimulus-vite-helpers"

// @ts-ignore
import controllers from "stimulus-controllers" //this will be provided by rollup plugin
import "./services/rollbar"

/*
 * We load the lazyload controller after we work out whether we support Webp
 */
const application = Application.start()
const controllers = import.meta.globEager("./controllers/*_controller.ts")
application.load(definitionsFromGlob(controllers))

Turbo.start()

document.addEventListener('turbo:load', (event: CustomEvent) => {
    const anchor = getAnchor(window.location)

    if (anchor) {
        const element = document.getElementById(anchor)
        element && element.scrollIntoView()
    }
})

document.addEventListener('turbo:click', _ => {
    if (document.activeElement) {
        (document.activeElement as HTMLElement).blur()
    }
})

function getAnchor(url: Location) {
    let anchorMatch
    if (url.hash) {
        return url.hash.slice(1)
    } else if (anchorMatch = url.href.match(/#(.*)$/)) {
        return anchorMatch[1]
    } else {
        return false
    }
}

//Required because we set isolatedModules to true in tsconfig as per vite recommendation
//https://vitejs.dev/guide/features.html#isolatedmodules
export {}
