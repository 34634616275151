import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

export default class extends Controller {
    onBeforeVisit: any
    onScroll: any
    onDebouncedScroll: any

    currentScrollY: number
    navHeight: number

    constructor(context) {
        super(context)
        this.navHeight = window.matchMedia("(max-width: 1023)").matches
            ? 80
            : 110

        this.onBeforeVisit = this.beforeVisit.bind(this)
        this.onScroll = this.scroll.bind(this)
        this.onDebouncedScroll = this.debouncedScroll.bind(this)
    }

    beforeVisit(event: CustomEvent) {
        //Hide mobile nav
        this.button.classList.remove("active")
        this.menu.classList.add("hidden")
        document.body.classList.remove("with-mobile-nav")
    }

    scroll() {
        const classList = this.element.classList
        if (window.scrollY <= 5) {
            this.currentScrollY = 0
            classList.remove("hide")
            classList.remove("show")
            classList.remove("text-black")
            classList.add("text-white")
            return
        }

        if (window.scrollY > this.currentScrollY) {
            if (this.currentScrollY > this.navHeight) {
                classList.add("hide")
                classList.remove("show")
                classList.remove("text-white")
                classList.add("text-black")
            }
        } else {
            classList.add("show")
            classList.remove("hide")
            classList.remove("text-white")
            classList.add("text-black")
        }

        this.currentScrollY = window.scrollY
    }

    debouncedScroll() {
        // Make sure they scroll more than delta
        const delta = window.scrollY - this.currentScrollY
        if (delta * delta < 5) {
            return
        }

        this.onScroll()
    }

    connect() {
        document.addEventListener("turbo:before-visit", this.onBeforeVisit)
        window.addEventListener("scroll", this.onDebouncedScroll)

        this.currentScrollY = window.scrollY
    }

    disconnect() {
        console.log("disconnect")
        document.removeEventListener("turbo:before-visit", this.onBeforeVisit)

        window.removeEventListener("scroll", this.onDebouncedScroll)
    }

    toggle(event: Event) {
        const target = event.currentTarget as HTMLInputElement
        const checked = target.checked
        this.checkboxes.forEach((checkbox) => {
            checkbox.checked = checkbox.id != target.id ? false : checked
        })
    }

    showHide() {
        const target = event.currentTarget as HTMLInputElement
        target.classList.toggle("active")
        this.menu.classList.toggle("hidden")
        if (target.classList.contains("active")) {
            document.body.classList.add("with-mobile-nav")
        } else {
            document.body.classList.remove("with-mobile-nav")
        }
    }

    get checkboxes(): HTMLInputElement[] {
        return this.targets.findAll("checkbox") as HTMLInputElement[]
    }

    get menu(): HTMLElement {
        return this.targets.find("menu") as HTMLElement
    }

    get button(): HTMLElement {
        return this.targets.find("button") as HTMLElement
    }

    get menubar(): HTMLElement {
        return this.targets.find("menubar") as HTMLElement
    }

    blur(event) {
        ;(document.activeElement as HTMLElement).blur()
    }

    keydown(event: KeyboardEvent) {
        if (!(event.target instanceof HTMLElement)) {
            return
        }

        const target = event.target as HTMLAnchorElement & {
            ariaHasPopup: string
        }

        if (["ArrowUp", "ArrowDown"].includes(event.key)) {
            event.preventDefault()

            const group = target.closest(".group")
            const links = Array.from(group.getElementsByTagName("a"))
            let nextLink: HTMLElement

            links.forEach((link, index) => {
                if (link != target) {
                    link.tabIndex = -1
                    return
                }

                switch (event.key) {
                    case "ArrowUp":
                        nextLink =
                            index == 0
                                ? links[links.length - 1]
                                : links[index - 1]
                        break

                    case "ArrowDown":
                        nextLink =
                            index < links.length - 1
                                ? links[index + 1]
                                : links[0]
                        break
                }
            })

            nextLink.tabIndex = 0
            nextLink.focus()
            return
        }

        if ([" ", "Enter"].includes(event.key)) {
            event.preventDefault()
            Turbo.visit(target.href)
        }

        if (event.key == "Tab" && target.ariaHasPopup != "true") {
            const group = target.closest(".group")

            //Set the top menu element tabIndex back to 0 and other to -1
            const links = Array.from(group.getElementsByTagName("a"))
            links.forEach((link, index) => {
                link.tabIndex = index == 0 ? 0 : -1
            })

            if (event.shiftKey) {
                if (group.previousElementSibling) {
                    ;(
                        group.previousElementSibling
                            .firstElementChild as HTMLElement
                    ).focus()
                    event.preventDefault()
                }
            } else {
                if (group.nextElementSibling) {
                    ;(
                        group.nextElementSibling
                            .firstElementChild as HTMLElement
                    ).focus()
                    event.preventDefault()
                }
            }
        }
    }
}
