import { IndeedEvent } from "../events/IndeedEvent"
import { Context, Controller } from "@hotwired/stimulus"

export default class extends Controller {
    iframe: Window
    onMessage: any
    onInit: any
    indeedIFrame: HTMLElement
    jobId: string

    constructor(context: Context) {
        super(context)
        this.onMessage = this.message.bind(this)
        this.onInit = this.init.bind(this)
    }

    connect() {
        window.addEventListener("message", this.onMessage)
        this.element.addEventListener(IndeedEvent.type, this.onInit)
        this.initIFrame((this.element as HTMLElement).dataset.jobId)
    }

    disconnect() {
        window.removeEventListener("message", this.onMessage)
        this.element.removeEventListener(IndeedEvent.type, this.onInit)
    }

    open() {
        if (!this.indeedIFrame) {
            return
        }

        this.indeedIFrame.classList.remove("hidden")

        const frame = document.getElementById(
            `indeed-iframe-${this.jobId}`
        ) as HTMLIFrameElement
        frame &&
            frame.contentWindow.postMessage(
                {
                    type: "clickButton",
                },
                window.location.origin
            )
    }

    init(event: IndeedEvent) {
        this.initIFrame(event.data)
    }

    initIFrame(jobId) {
        if (!jobId || this.jobId == jobId) {
            return
        }

        if (this.indeedIFrame) {
            this.indeedIFrame.remove()
        }

        this.jobId = jobId

        this.indeedIFrame = document.createElement("iframe")
        this.indeedIFrame.setAttribute("src", `/indeed/${this.jobId}`)
        this.indeedIFrame.setAttribute("id", `indeed-iframe-${this.jobId}`)
        this.indeedIFrame.setAttribute(
            "class",
            "absolute z-100 hidden w-full h-full"
        )

        document.body.appendChild(this.indeedIFrame)
    }

    message(event: MessageEvent) {
        switch (event.data) {
            case "indeedIFrameLoaded":
                //                window.frames[0].postMessage()
                break
            case "indeedPopupClosed":
                this.popupClosed()
                break
        }
    }

    popupClosed() {
        this.indeedIFrame.classList.add("hidden")
    }
}
