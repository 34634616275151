import { Controller } from "@hotwired/stimulus"
import Flickity from "flickity"

export default class extends Controller {
    connect() {
        if (window.matchMedia("(min-width: 768px)").matches) {
            return
        }

        const options : Flickity.Options = {
            cellAlign: 'left',
            prevNextButtons: false,
            contain: true,
            freeScroll: (this.element as HTMLElement).dataset.hasOwnProperty('freeScroll')
        }

        if ((this.element as HTMLElement).dataset.initialIndex) {
            options.initialIndex = parseInt((this.element as HTMLElement).dataset.initialIndex)
        }

        const flkty = new Flickity(this.base, options)

        const scroller = this.targets.find('scroller') as HTMLElement
        const scrollerContainer = this.targets.find('scroller-container') as HTMLElement
        if (scroller && scrollerContainer) {
            let offset = 0
            if (scroller.offsetWidth == 0) {
                scroller.onload = () => {
                    offset = scrollerContainer.offsetWidth - scroller.offsetWidth //25 for side margin
//                    scroller.style.transform = `translateX(${offset}px)`
                }
            } else {
                offset = scrollerContainer.offsetWidth - scroller.offsetWidth - 25 //25 for side margin
//                scroller.style.transform = `translateX(${offset}px)`
            }
            flkty.on('scroll', (progress:number) => {
                scroller.style.transform = `translateX(${progress * offset}px)`
            })
        }
    }

    get base() : HTMLElement {
        return this.targets.find('base') as HTMLElement ?? this.element as HTMLElement;
    }
}
