import { Controller } from "@hotwired/stimulus"
import Lottie, { AnimationEventName, AnimationItem } from "lottie-web"

interface BMEnterFrameEvent {
    currentTime: number
    totalTime: number
}

class LottieEvents {
    static ENTER_FRAME: AnimationEventName = "enterFrame"
    static COMPLETE: AnimationEventName = "complete"
}

interface Panel {
    id: string
    progress: HTMLElement
    animationElement: HTMLElement
    panel: HTMLElement
    content: HTMLElement
    data: object
    animationItem: AnimationItem
}

export default class extends Controller {
    static targets = [
        "panel",
        "progress",
        "animation",
        "mobileAnimation",
        "content",
        "desktop",
    ]

    declare readonly progressTargets: HTMLElement[]
    declare readonly panelTargets: HTMLElement[]
    declare readonly desktopTarget: HTMLElement
    declare readonly animationTargets: HTMLElement[]
    declare readonly mobileAnimationTargets: HTMLElement[]
    declare readonly contentTargets: HTMLElement[]

    private static ENTER_FRAME: string = "enterFrame"
    private static COMPLETE: string = "complete"

    currentAnimation!: AnimationItem
    currentProgressTarget!: HTMLElement
    animationData: { [id: string]: object } = {}
    currentIndex = -1
    isMobile = false

    currentAnimationTargets: HTMLElement[]

    onEnterFrameHandler: (BMEnterFrameEvent) => void
    onCompleteHandler: (Event) => void

    panels: Panel[] = []

    connect() {
        this.onEnterFrameHandler = this.onEnterFrame.bind(this)
        this.onCompleteHandler = this.onComplete.bind(this)

        this.isMobile = this.desktopTarget.offsetParent == null

        this.currentAnimationTargets = this.isMobile
            ? this.mobileAnimationTargets
            : this.animationTargets

        this.panels = this.panelTargets.map((target, index) => ({
            id: target.dataset["panelId"],
            panel: this.panelTargets[index],
            progress: this.progressTargets[index],
            animationElement: this.currentAnimationTargets[index],
            content: this.contentTargets[index],
            data: JSON.parse(target.dataset["lottie"]),
            animationItem: null,
        }))

        this.startAnimation(0)
    }

    toggleAnimation(event: Event) {
        const target = event.currentTarget as HTMLElement

        if (target.dataset["panelId"] == this.currentPanel.id) {
            if (this.currentPanel.animationItem.isPaused) {
                this.currentPanel.animationItem.play()
            } else {
                this.panels[this.currentIndex].animationItem.pause()
            }

            return
        }

        this.startAnimation(
            this.panels.findIndex(
                (panel) => panel.id == target.dataset["panelId"]
            )
        )
    }

    startAnimation(index: number) {
        if (this.currentAnimation) {
            this.currentAnimation.stop()
            this.currentAnimation.removeEventListener(
                LottieEvents.ENTER_FRAME,
                this.onEnterFrameHandler
            )
            this.currentAnimation.removeEventListener(
                LottieEvents.COMPLETE,
                this.onCompleteHandler
            )
        }

        this.currentIndex = index
        const panel = this.currentPanel

        if (panel.animationItem == null) {
            panel.animationItem = Lottie.loadAnimation({
                container: panel.animationElement, // the dom element that will contain the animation
                renderer: "svg",
                loop: false,
                autoplay: false,
                animationData: panel.data, // the path to the animation json
            })
        }

        this.currentAnimation = panel.animationItem

        this.currentAnimation.addEventListener(
            LottieEvents.ENTER_FRAME,
            this.onEnterFrameHandler
        )
        this.currentAnimation.addEventListener(
            LottieEvents.COMPLETE,
            this.onCompleteHandler
        )

        this.currentAnimation.play()

        for (const target of this.progressTargets) {
            target.style.width = "0%"
        }

        for (const _panel of this.panels) {
            if (_panel == panel) {
                _panel.animationElement.classList.remove("opacity-0")
                _panel.animationElement.classList.add("opacity-100")
            } else {
                _panel.animationElement.classList.remove("opacity-100")
                _panel.animationElement.classList.add("opacity-0")
            }
        }
    }

    onEnterFrame(event: BMEnterFrameEvent) {
        this.panels[this.currentIndex].progress.style.width = `${
            (event.currentTime / event.totalTime) * 100
        }%`
    }

    onComplete(event: Event) {
        this.startAnimation(
            this.currentIndex >= this.panels.length - 1
                ? 0
                : this.currentIndex + 1
        )
    }

    get currentPanel(): Panel {
        return this.panels[this.currentIndex]
    }
}
