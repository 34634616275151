import { Controller } from "@hotwired/stimulus"
import { popup_controller } from "./popup_controller"

export default class extends Controller {
    blurHandler: any
    targetLink: HTMLAnchorElement

    disconnect() {
        this.cleanup()
    }

    /*
     * If the modal was closed directly then the blur event listener won't be cleaned up
     */
    cleanup() {
        if (this.targetLink && this.blurHandler) {
            this.targetLink.removeEventListener("blur", this.blurHandler)
            this.targetLink = null
            this.blurHandler = null
        }
    }

    confirm(event: Event) {
        this.cleanup()

        this.targetLink = this.getTarget(event)
        if (!this.targetLink) {
            return
        }

        this.anchor.href = this.targetLink.href
        event.preventDefault()
        this.popup.open(event)

        /*
         * We don't want the focus ring to show the first time the popup is opened
         * so we add this class here and then remove it as soon as the focus moves away.
         * That way the focus will show the next time we focus on it.
         */
        this.anchor.classList.add("hide-focus")
        this.blurHandler = this.blur.bind(this)
        this.anchor.addEventListener("blur", this.blurHandler)
    }

    getTarget(event: Event): HTMLAnchorElement {
        const target = (event.target as HTMLElement).closest(
            "a"
        ) as HTMLAnchorElement

        //Not a link click
        if (!target?.href) {
            return null
        }

        //Local navigation link
        if (
            target.href.startsWith("/") ||
            target.href.includes(window.location.hostname) ||
            target.href.includes("ir.entradatx.com")
        ) {
            return null
        }

        //Click on the popup OK button
        if (target.dataset.linkTarget || target.dataset.externalLink) {
            return null
        }

        return target
    }

    blur() {
        this.anchor.removeEventListener("blur", this.blurHandler)
        this.anchor.classList.remove("hide-focus")
    }

    confirmed() {
        this.cleanup()
        this.popup.close()
    }

    denied() {
        this.cleanup()
        this.popup.close()
    }

    get popup(): popup_controller {
        return document.body["popup"] as popup_controller
    }

    get anchor(): HTMLAnchorElement {
        return this.targets.find("anchor") as HTMLAnchorElement
    }
}
