import { ActionEvent } from "@hotwired/stimulus"
import ShofskyController from "./ShofskyController"

import "@interactjs/auto-start"
import "@interactjs/core/Interactable"
import "@interactjs/actions/drag"
import "@interactjs/actions/drag/plugin"
import interact from "@interactjs/interact"

export default class extends ShofskyController {
    static targets = ["indicator", "track"]

    readonly indicatorTargets!: HTMLInputElement[]
    readonly trackTarget!: HTMLElement

    connect() {
        if (this.indicatorTargets && this.indicatorTargets.length > 0) {
            this.addEventListener(this.trackTarget, "scroll", this.onScroll)
        }

        if (this.isTouchEnabled) return //On touch screens horizontal scroll will be handled for us

        interact(this.trackTarget, {
            // @ts-ignore
            styleCursor: false,
        }).draggable({
            origin: "self",
            listeners: {
                start: (event) => {
                    this.trackTarget.classList.remove(
                        "snap-x",
                        "snap-mandatory",
                        "scroll-smooth"
                    )
                },
                move: (event) => {
                    this.trackTarget.style.cursor = "grab"
                    this.trackTarget.scrollLeft -= event.dx
                },
                end: () => {
                    this.trackTarget.classList.add("scroll-smooth")
                    this.element.clientWidth
                    const index = Math.round(
                        this.trackTarget.scrollLeft / this.element.clientWidth
                    )
                    this.trackTarget.scrollTo(
                        this.element.clientWidth * index - 1,
                        0
                    )
                },
            },
        })

        this.trackTarget.style.cursor = "grab"
    }

    toSlide(event: ActionEvent) {
        this.trackTarget.scrollTo(
            this.element.clientWidth * (event.params["index"] - 1),
            0
        )
    }

    /*
     * Update the indicator targets based on the scroll position
     */
    onScroll(event: Event) {
        this.element.clientWidth
        const index = Math.round(
            this.trackTarget.scrollLeft / this.element.clientWidth
        )
        this.indicatorTargets[index].checked = true
    }
}
