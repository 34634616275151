import { Controller } from "@hotwired/stimulus"
import Player from "@vimeo/player"

export default class VimeoController extends Controller<HTMLElement> {
    static values = {
        url: String,
    }

    declare readonly urlValue: string

    player: Player
    playButtonHasBeenClicked = false

    connect() {
        // Set up the params
        let params = {
            url: this.urlValue,
            controls: true,
            byline: false,
            title: false,
            autoplay: false,
            interactive_params: "subtitle=interactive",
            texttrack: "en"
        }

        // Get any passed in values
        const customParams = new URL(this.urlValue).searchParams;
        for (const [key, value] of customParams.entries()) {
            params[key] = value
        }

        this.player = new Player(this.element, params)

        this.player.ready().then(() => {
            if (this.playButtonHasBeenClicked) {
                this.play()
            }
            this.element
                .querySelector("iframe")
                .classList.add("w-full", "h-full", "relative", "z-10", "hidden")
        })

        this.player.on("ended", this.reset.bind(this))
    }

    play() {
        this.playButtonHasBeenClicked = true
        this.player.play()

        const iframe = this.element.querySelector("iframe")
        iframe.classList.remove("hidden")
    }

    reset() {
        if (this.element.querySelector("picture")) {
            this.element.querySelector("iframe").classList.add("hidden")
        }
    }
}
