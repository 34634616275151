import { modal_controller, TargetData } from "./modal_controller"

export class popup_controller extends modal_controller {
    initContent(data: TargetData) {
        this.initKeyBoardNavigation(this.content)
    }

    positionModal(data) {
        const modalTop = window.matchMedia("(max-width: 1023px)").matches
            ? 0
            : 80

        //The default close method will scroll to currentScrollY so we need to set it here
        this.currentScrollY = window.scrollY

        this.popupTarget.style.marginTop = modalTop + "px"
        this.modalTarget.classList.add("active")
    }

    get content(): HTMLElement {
        return this.targets.find("content") as HTMLElement
    }
}

export default popup_controller
